import classNames from "classnames"
import { FC } from "react"

interface Props {
  className?: string
}

export const Badge: FC<Props> = ({ children, className }) => (
  <span
    className={classNames(
      "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-indigo-700/10 dark:ring-indigo-400/30 bg-indigo-50 dark:bg-indigo-400/10 text-indigo-700 dark:text-indigo-400",
      className
    )}
  >
    {children}
  </span>
)
